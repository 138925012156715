import * as React from "react";
import styled from "styled-components";

import profile from '../images/profile.jpeg';

export function Profile() {
    return (
      <SectionProfile className="profile-container">
        <ProfileWrapper>
          <ProfileContent>
            <ImageColumn>
              <ProfileImage
                loading="lazy"
                src={profile}
                alt="Profile"
              />
            </ImageColumn>
            <DetailsColumn>
              <ProfileDetails>
                <NameTitle>Guilherme Cardoso</NameTitle>
                <RoleTitle>ML Engineer</RoleTitle>
                <EmailTitle>contato@kardoso.dev</EmailTitle>
              </ProfileDetails>
            </DetailsColumn>
          </ProfileContent>
        </ProfileWrapper>
      </SectionProfile>
    );
  }
  
  const SectionProfile = styled.section`
    background-color: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Inter, sans-serif;
    @media (max-width: 768px) {
        width: 100%;
        padding-top: 50%;
        padding-bottom: 5%;
    }
  `;
  
  const ProfileWrapper = styled.div`
    width: 100%;
    height: 100%;
    margin-top: 10.7%;
    margin-bottom: 9%;
  `;
  
  const ProfileContent = styled.div`
    display: flex;
    @media (max-width: 768px) {
        flex-direction: column;
    }
  `;
  
  const ImageColumn = styled.div`
    flex: 0 1 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
        width: 100%;
    }   
  `;
  
  const ProfileImage = styled.img`
    width: 60%;
    aspect-ratio: 1;
    border-radius: 100%;
    object-fit: cover;
    @media (max-width: 768px) {
      width: 70%;
  }
  `;
  
  const DetailsColumn = styled.div`
    flex: 1 1 50%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 768px) {
        width: 100%;
        flex-direction: row;
        margin-top: 10%;
    }
  `;
  
  const ProfileDetails = styled.div`
    margin: auto 0;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `;
  
  const NameTitle = styled.h2`
    margin: 0;
    font: 150 200% Inter, sans-serif;
    @media (max-width: 768px) {
        text-align: center;
        font: 150 170% Inter, sans-serif;
    }
  `;
  
  const RoleTitle = styled.h3`
    margin: 0;
    margin-top: -3%;
    margin-bottom: -3%;
    font: 900 500% Inter, sans-serif;
    @media (max-width: 768px) {
        font: 900 170% Inter, sans-serif;
        text-align: center;
        margin-bottom: 90%;
    }
  `;

  const EmailTitle = styled.h2`
    margin: 0;
    font: 150 100% Inter, sans-serif;
    @media (max-width: 768px) {
        text-align: center;
        margin-top: 1%;
        font: 150 110% Inter, sans-serif;
    }
  `;