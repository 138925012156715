import * as React from "react";
import styled from "styled-components";

import { Profile, Timeline, Stacks } from "../../components";

export default function Home() {
  return (
    <div>
      <Profile />
      <Timeline />
      <Stacks />
    </div>
  );
}

const screen = styled.div`
  width: 100%;
  height: 100%;
`;