import * as React from "react";
import styled from "styled-components";

import {
    airflow,
    beautyfulsoup,
    django,
    duckdb,
    flask,
    js,
    keras,
    mongodb,
    neo4j,
    opencv,
    postgres,
    python,
    pytorch,
    react,
    scrapy,
    selenium,
    sklearn,
    spacy,
    tensorflow
} from '../images/logos'

export function Stacks() {
    return (
        <Section>
            <Block>
                <Title>Linguagens</Title>
                <Images>
                    <Image loading="lazy" src={python} alt="Python"/>
                    <Image loading="lazy" src={js} alt="Javascript"/>
                </Images>
            </Block>
            <Block>
                <Title>Machine Learning</Title>
                <Images>
                    <Image loading="lazy" src={sklearn} alt="Scikit Learn"/>
                    <Image loading="lazy" src={pytorch} alt="Pytorch"/>
                    <Image loading="lazy" src={tensorflow} alt="Tensorflow"/>
                    <Image loading="lazy" src={keras} alt="Keras"/>
                    <Image loading="lazy" src={spacy} alt="Spacy"/>
                    <Image loading="lazy" src={opencv} alt="OpenCv"/>
                </Images>
            </Block>
            <Block>
                <Title>Banco de dados</Title>
                <Images>
                    <Image loading="lazy" src={mongodb} alt="MongoDB"/>
                    <Image loading="lazy" src={postgres} alt="Postgres"/>
                    <Image loading="lazy" src={neo4j} alt="Neo4j"/>
                    <Image loading="lazy" src={duckdb} alt="DuckDB"/>
                </Images>
            </Block>
            <Block>
                <Title>Web</Title>
                <Images>
                    <Image loading="lazy" src={flask} alt="Flask"/>
                    <Image loading="lazy" src={django} alt="Django"/>
                    <Image loading="lazy" src={react} alt="React"/>
                </Images>
            </Block>
            <Block>
                <Title>Web scraping</Title>
                <Images>
                    <Image loading="lazy" src={scrapy} alt="Scrapy"/>
                    <Image loading="lazy" src={beautyfulsoup} alt="BeautyfulSoup"/>
                    <Image loading="lazy" src={selenium} alt="Selenium"/>
                </Images>
            </Block>
            <Block>
                <Title>Ferramentas</Title>
                <Images>
                    <Image loading="lazy" src={airflow} alt="Airflow"/>
                </Images>
            </Block>
        </Section>
    );
}

const Section = styled.section`
    background-color: #fff;
    width: 100%;
    display: flex;
    padding-top: 10%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: Inter, sans-serif;
    @media (max-width: 768px) {
        width: 100%;
        padding-top: 17%;
        padding-bottom: 7%;
    }
`;

const Block = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 4%;
    width: 100%;
    @media (max-width: 768px) {
        margin-bottom: 10%;
    }
`;

const Title = styled.h2`
    color: #000;
    margin: 0;
    font: 900 150%;
    margin-bottom: 0.7%;
    @media (max-width: 768px) {
        margin-bottom: 3%;
    }
`;

const Images = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
`;

const Image = styled.img`
    height: 70px;
    margin-left: 2%;
    margin-right: 2%;
    object-fit: cover;
    @media (max-width: 768px) {
        height: 48px;
        margin: 1.8%;
    }
`;