import * as React from "react";
import styled from "styled-components";

export function Timeline() {
  return (
    <SectionTimeline>
        <TimelineWrapper>
          <BoxContent>

            <TextLabelColumn $position='left'>
                <Title>Formações</Title>
            </TextLabelColumn>

            <TextLabelColumn $position='right'>
                <Title>Experiências</Title>
            </TextLabelColumn>
            
          </BoxContent>
          <InitialDateContent>
            <Date>atual</Date>
          </InitialDateContent>
          <BoxContent>
            <LabelColumn $position='left'>
                
                <Register $size = {1.3} $position='left'>
                    <RegisterTitleBox $position ='left'>
                        <FunctionTitle>Pós em Engenharia de Software</FunctionTitle>
                        <InstituteTitle>PUC Minas</InstituteTitle>
                    </RegisterTitleBox>
                    <MeasureBox $position ='left'>
                        <DateBox $position ='left'>
                            <InitialDate></InitialDate>
                            <EndDate>jan 2024</EndDate>
                        </DateBox>
                        <MeasureBar/>
                    </MeasureBox>
                </Register>
                <Pause $size = {1/12*5} />
                
                <Register $size = {2.83333} $position='left'>
                    <RegisterTitleBox $position ='left'>
                        <FunctionTitle>Ciência de Dados</FunctionTitle>
                        <InstituteTitle>UNICESUMAR</InstituteTitle>
                    </RegisterTitleBox>
                    <MeasureBox $position ='left'>
                        <DateBox $position ='left'>
                            <InitialDate>nov 2023</InitialDate>
                            <EndDate>jan 2021</EndDate>
                        </DateBox>
                        <MeasureBar/>
                    </MeasureBox>
                </Register>
                
                <Register $size = {4} $position='left'>
                    <RegisterTitleBox $position ='left'>
                        <FunctionTitle>Técnico em Informática</FunctionTitle>
                        <InstituteTitle>Instituto Federal do Paraná</InstituteTitle>
                    </RegisterTitleBox>
                    <MeasureBox $position ='left'>
                        <DateBox $position ='left'>
                            <InitialDate></InitialDate>
                            <EndDate>jan 2017</EndDate>
                        </DateBox>
                        <MeasureBar/>
                    </MeasureBox>
                </Register>
            </LabelColumn>
            <LabelColumn $position='right'>
                
                <Register $size = {2.15} $position='right'>
                    <MeasureBox $position ='right'>
                        <MeasureBar/>
                        <DateBox $position ='right'>
                            <InitialDate></InitialDate>
                            <EndDate>jul 2023</EndDate>
                        </DateBox>
                    </MeasureBox>
                    <RegisterTitleBox $position='right'>
                        <FunctionTitle>Engenheiro de Machine Learning</FunctionTitle>
                        <InstituteTitle>Datarisk</InstituteTitle>
                    </RegisterTitleBox>
                </Register>
                
                <Register $size = {0.9} $position='right'>
                    <MeasureBox $position ='right'>
                        <MeasureBar/>
                        <DateBox $position ='right'>
                            <InitialDate></InitialDate>
                            <EndDate>jun 2022</EndDate>
                        </DateBox>
                    </MeasureBox>
                    <RegisterTitleBox $position='right'>
                        <FunctionTitle>Engenheiro de Machine Learning</FunctionTitle>
                        <InstituteTitle>EEmovel</InstituteTitle>
                    </RegisterTitleBox>
                </Register>
                
                <Register $size = {1/12*4} $position='right'>
                    <MeasureBox $position ='right'>
                        <MeasureBar/>
                        <DateBox $position ='right'>
                            <InitialDate></InitialDate>
                            <EndDate>mar 2022</EndDate>
                        </DateBox>
                    </MeasureBox>
                    <RegisterTitleBox $position='right'>
                        <FunctionTitle>Analytics Engineer</FunctionTitle>
                        <InstituteTitle>EEmovel</InstituteTitle>
                    </RegisterTitleBox>
                </Register>
                
                <Register $size = {1/12*9} $position='right'>
                    <MeasureBox $position ='right'>
                        <MeasureBar/>
                        <DateBox $position ='right'>
                            <InitialDate></InitialDate>
                            <EndDate>jul 2021</EndDate>
                        </DateBox>
                    </MeasureBox>
                    <RegisterTitleBox $position='right'>
                        <FunctionTitle>Desenvolvedor Full Stack</FunctionTitle>
                        <InstituteTitle>NIB Cascavel</InstituteTitle>
                    </RegisterTitleBox>
                </Register>
                <Pause $size = {2.35} />
                
                <Register $size = {1/12*9} $position='right'>
                    <MeasureBox $position ='right'>
                        <MeasureBar/>
                        <DateBox $position ='right'>
                            <InitialDate></InitialDate>
                            <EndDate>jul 2021</EndDate>
                        </DateBox>
                    </MeasureBox>
                    <RegisterTitleBox $position='right'>
                        <FunctionTitle>Analista de Dados</FunctionTitle>
                        <InstituteTitle>EEmovel</InstituteTitle>
                    </RegisterTitleBox>
                </Register>
            </LabelColumn>
          </BoxContent>
        </TimelineWrapper>
      </SectionTimeline>
  );
}

const SectionTimeline = styled.section`
    background-color: #1E1E1E;
    width: 100%;
    height: 150%;
    display: flex;
    padding-bottom: 5%;
    align-items: center;
    justify-content: center;
    font-family: Inter, sans-serif;
`;

const TimelineWrapper = styled.div`
    width: 100%;
    height: 100%;
    margin-top: 8%;
`;

const BoxContent = styled.div`
    display: flex;
    margin-bottom: 5%;
`;

const LabelColumn = styled.div`
    flex: 0 1 50%;
    display: flex;
    flex-direction: column;
    justify-content: ${props => props.$position == 'left' ? 'right' : 'left'};
    align-items: center;
`;

const TextLabelColumn = styled.div`
    flex: 0 1 50%;
    display: flex;
    margin-left: 1%;
    margin-right: 1%;
    justify-content: ${props => props.$position == 'left' ? 'end' : 'start'};
    align-items: center;
`;

const Register = styled.div`
    text-align: ${props => props.$position == 'left' ? 'right' : 'left'};
    height: ${props => props.$size * 120}px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2px;
`;

const RegisterTitleBox  = styled.div`
    width: 100%;
    display: flex;
    margin-${props => props.$position}: 5%;
    flex-direction: column;
    justify-content: center;
`;


const Title = styled.h2`
    color: #f0f0f0;
    margin: 0;
    font: 900 150%;
`;

const InitialDateContent = styled.div`
    display: flex;
    justify-content: center;
`;

const Date = styled.p`
    color: #f0f0f0;
    margin: 0;
    font-size: 70%;
`;

const MeasureBox  = styled.div`
    display: flex;
    justify-content: center;
    width: 15%;
    margin-${props => props.$position}: 7%;
    margin-${props => props.$position == 'left' ? 'right' : 'left'}: 1%;
    @media (max-width: 768px) {
        width: 30%;
    }
`;

const DateBox  = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    width: 70%;
    margin-${props => props.$position == 'left' ? 'right' : 'left'}: 10%;
    @media (max-width: 768px) {
        width: 50%;
    }
`;

const InitialDate = styled.p`
    color: #f0f0f0;
    margin: 0;
    font-size: 70%;
    margin-bottom: auto;
`;

const EndDate = styled.p`
    color: #f0f0f0;
    margin: 0;
    font-size: 70%;
    margin-top: auto;
`;

const MeasureBar = styled.div`
    width: 30%;
    height: 100%;
    background-color: #f0f0f0;
`;

const FunctionTitle = styled.p`
    color: #f0f0f0;
    margin: 0;
    font-size: 110%;
`;

const InstituteTitle = styled.h3`
    color: #f0f0f0;
    margin: 0;
    font-size: 110%;
    font-weight: 1000;
`;

const Pause = styled.div`
height: ${props => props.$size * 120}px;
`;